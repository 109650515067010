import _stream from "stream";
var exports = {};
const {
  Readable
} = _stream;

exports = input => new Readable({
  read() {
    this.push(input);
    this.push(null);
  }

});

export default exports;